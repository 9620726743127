import { IconGalaxy, IconUniverse } from '@tabler/icons-react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { HashLink as Link } from 'react-router-hash-link';
import whiteapp from '../../assets/images/appstore_white.png';
import frame from '../../assets/images/mobile_frame_svg.svg';
import screenshot1 from '../../assets/images/screenshots2/screenshot-1.png';
import screenshot2 from '../../assets/images/screenshots2/screenshot-2.png';
import screenshot3 from '../../assets/images/screenshots2/screenshot-3.png';
import screenshot4 from '../../assets/images/screenshots2/screenshot-4.png';
import screenshot5 from '../../assets/images/screenshots2/screenshot-5.png';
import screenshot6 from '../../assets/images/screenshots2/screenshot-6.png';

function Sliderone() {
  const frmae_slider = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    smartSpeed: 1500,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  return (
    <>
      <section className='banner_section home-banner'>
        <div className='container'>
          {/* <div className='anim_line'>
            <span>
              <img src={anim} alt='anim_line' />
            </span>
            <span>
              <img src={anim} alt='anim_line' />
            </span>
            <span>
              <img src={anim} alt='anim_line' />
            </span>
            <span>
              <img src={anim} alt='anim_line' />
            </span>
            <span>
              <img src={anim} alt='anim_line' />
            </span>
            <span>
              <img src={anim} alt='anim_line' />
            </span>
            <span>
              <img src={anim} alt='anim_line' />
            </span>
            <span>
              <img src={anim} alt='anim_line' />
            </span>
            <span>
              <img src={anim} alt='anim_line' />
            </span>
          </div> */}
          <div className='row'>
            <div
              className='col-lg-6 col-md-12'
              data-aos='fade-right'
              data-aos-duration='1500'
              style={{ paddingTop: 150 }}
            >
              {/* <div
                style={{
                  paddingTop: 30,
                  paddingBottom: 70,
                  color: 'white',
                  fontSize: 30,
                }}
              >
                <span className='text-gradient'>coming</span>
                soon
              </div> */}
              <div className='banner_text'>
                <h1>
                  Building better <br />
                  <span>connections</span>
                </h1>
                <p>
                  Connect with partners, friends and family to gain insights
                  into your life together, helping you navigate the complexities
                  of the modern world.
                </p>
              </div>
              <ul className='app_btn' style={{ marginLeft: -30 }}>
                <li>
                  <Link
                    target='_blank'
                    to='https://apps.apple.com/gb/app/galactics/id6475806735'
                  >
                    <img className='blue_img' src={whiteapp} alt='image' />
                    {/* <img className='white_img' src={whiteapp} alt='image' /> */}
                  </Link>
                </li>
                {/* <li>
                  <Link to='#'>
                    <img className='blue_img' src={white} alt='image' />
                  </Link>
                </li> */}
              </ul>
              {/* <div className='used_app'>
                <ul>
                  <li>
                    <img src={used1} alt='image' />
                  </li>
                  <li>
                    <img src={used2} alt='image' />
                  </li>
                  <li>
                    <img src={used3} alt='image' />
                  </li>
                  <li>
                    <img src={used4} alt='image' />
                  </li>
                </ul>
                <p>
                  12M + <br /> used this app
                </p>
              </div> */}
            </div>
            <div
              className='col-lg-6 col-md-12'
              data-aos='fade-in'
              data-aos-duration='1500'
            >
              <div className='banner_slider' style={{ paddingTop: 100 }}>
                <div className='left_icon'>
                  <IconGalaxy
                    size={64}
                    strokeWidth={1}
                    style={{ stroke: 'url(#gradient)' }}
                  />
                  {/* <img src={msg} alt='image' /> */}
                </div>
                <div className='right_icon'>
                  <IconUniverse
                    size={64}
                    strokeWidth={1}
                    style={{ stroke: 'url(#gradient)' }}
                  />
                  {/* <img src={shield} alt='image' /> */}
                </div>
                <OwlCarousel
                  id='frmae_slider'
                  className='owl-carousel owl-theme owl-loaded owl-drag'
                  {...frmae_slider}
                >
                  <div className='item'>
                    <div className='slider_img'>
                      <img src={screenshot1} alt='image' />
                    </div>
                  </div>
                  <div className='item'>
                    <div className='slider_img'>
                      <img src={screenshot2} alt='image' />
                    </div>
                  </div>
                  <div className='item'>
                    <div className='slider_img'>
                      <img src={screenshot3} alt='image' />
                    </div>
                  </div>
                  <div className='item'>
                    <div className='slider_img'>
                      <img src={screenshot4} alt='image' />
                    </div>
                  </div>
                  <div className='item'>
                    <div className='slider_img'>
                      <img src={screenshot5} alt='image' />
                    </div>
                  </div>
                  <div className='item'>
                    <div className='slider_img'>
                      <img src={screenshot6} alt='image' />
                    </div>
                  </div>
                </OwlCarousel>
                <div className='slider_frame'>
                  <img src={frame} alt='image' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Sliderone;
