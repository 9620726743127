import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Footer from '../component/Footer/Main';
import Home from '../component/Home/Main';
import Navbar from '../component/Navbar/Main';
import Privacy from '../component/Privacy/Main';
import Terms from '../component/Terms/Main';

const Routing = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/terms' element={<Terms />} />
      </Routes>
      <Footer />
    </>
  );
};

export default Routing;
