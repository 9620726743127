import React from 'react';
import { Link } from 'react-router-dom';

import { IconBrandTiktok } from '@tabler/icons-react';
import whiteapp from '../../assets/images/appstore_white.png';
import top from '../../assets/images/go_top.png';

const Main = () => {
  return (
    <>
      <footer>
        <div className='top_footer' id='contact'>
          <div
            className='container'
            style={{
              borderTop: 'solid 1px rgba(255, 255, 255, 0.1)',
              paddingTop: 30,
            }}
          >
            <div className='row'>
              <div className='col-lg-4 col-md-6 col-12'>
                <div className='abt_side'>
                  <div className='logo'>
                    <Link
                      to='/'
                      className='navbar-brand text-gradient'
                      style={{ fontFamily: 'Anthu', fontSize: 32 }}
                    >
                      GALACTICS
                    </Link>
                  </div>
                  {/* <div className='links'>
                    <ul>
                      <li>
                        <Link to='/'>Home</Link>
                      </li>
                      <li>
                        <Link to='#features'>Features</Link>
                      </li>
                      <li>
                        <Link to='#pricing'>Pricing</Link>
                      </li>
                      <li>
                        <Link to='#subscribe'>Subscribe</Link>
                      </li>
                    </ul>
                  </div> */}
                  <ul className='social_media'>
                    <li>
                      <Link
                        to='https://twitter.com/galacticscenter'
                        target='_blank'
                      >
                        <i className='icofont-twitter'></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='https://www.instagram.com/galactics.center'
                        target='_blank'
                      >
                        <i className='icofont-instagram'></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='https://www.tiktok.com/@galactics.center'
                        target='_blank'
                      >
                        <IconBrandTiktok strokeWidth={1} size={20} />
                        {/* <i className='icofont-pinterest'></i> */}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12'></div>
              <div className='col-lg-3 col-md-6 col-12'>
                <div className='links'>
                  <div style={{ paddingBottom: 20 }}>Support</div>
                  <ul>
                    <li>
                      <Link to='/terms'>Terms & conditions</Link>
                    </li>
                    <li>
                      <Link to='/privacy'>Privacy policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-lg-2 col-md-6 col-12'>
                <div className='try_out'>
                  {/* <h3>Let’s Try Out</h3> */}
                  <ul className='app_btn'>
                    <li>
                      <Link
                        target='_blank'
                        to='https://apps.apple.com/gb/app/galactics/id6475806735'
                      >
                        <img src={whiteapp} alt='image' />
                      </Link>
                    </li>
                    {/* <li>
                      <Link to='#'>
                        <img src={white} alt='image' />
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='bottom_footer'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6' style={{ paddingTop: 10 }}>
                <p>© Copyrights 2025. All rights reserved.</p>
              </div>
              <div className='col-md-6' style={{ textAlign: 'right' }}>
                <div className='logo'>
                  <Link
                    to='/'
                    className='navbar-brand text-gradient'
                    style={{ fontFamily: 'Anthu', fontSize: 32 }}
                  >
                    GALACTICS
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='go_top'>
          <span>
            <img src={top} alt='image' />
          </span>
        </div>
      </footer>
    </>
  );
};

export default Main;
