import AOS from 'aos';
import React, { useEffect } from 'react';
// import Features from '../HomeMain/Features/Main';
import Sliderone from '../Menhome/Sliderone';
import ParticlesBackground from '../ParticlesBackground';

const Main = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <Sliderone />
      {/* <Trusted /> */}
      {/* <Features /> */}
      {/* <AboutApp /> */}
      {/* <Design /> OLD LIVE */}
      {/* <Design2 /> OLD LIVE */}
      {/* <Work /> */}
      {/* <Testimonial /> */}
      {/* <Pricing /> */}
      {/* <Faq /> */}
      {/* <Interface /> */}
      {/* <Download /> */}
      {/* <Story /> */}
      <ParticlesBackground />
      <svg width='0' height='0'>
        <linearGradient id='gradient' x1='100%' y1='100%' x2='0%' y2='0%'>
          <stop stopColor='#748ffc' offset='0%' />
          <stop stopColor='#3bc9db' offset='100%' />
        </linearGradient>
      </svg>
    </>
  );
};

export default Main;
