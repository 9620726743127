import { IconBrandTiktok } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

const Main = ({ navbar }) => {
  const location = useLocation();
  const path = location.pathname;

  const [show, setShow] = useState();
  const [show1, setShow1] = useState();
  const [show2, setShow2] = useState();
  const [mobile, setmobile] = useState();

  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  const [menu, setmenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setmenu((prevMobile) => !prevMobile);
  };

  return (
    <>
      <header className={`header ${scrolled ? 'fix_style fixed' : ''}`}>
        <div className='container'>
          <nav className='navbar navbar-expand-lg'>
            <Link
              to='/'
              className='navbar-brand text-gradient'
              style={{ fontFamily: 'Anthu', fontSize: 38 }}
            >
              GALACTICS
            </Link>
            {/* <button
                    className='navbar-toggler'
                    type='button'
                    data-toggle='collapse'
                    data-target='#navbarSupportedContent'
                    aria-controls='navbarSupportedContent'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                  >
                    <div
                      className={`toggle-wrap ${mobile && 'active'}`}
                      onClick={() => setmobile(mobile === true ? false : true)}
                    >
                      <span className='toggle-bar'></span>
                    </div>
                  </button> */}
            <div className='collapse navbar-collapse'>
              <ul className='navbar-nav ml-auto'>
                {/* <li className='nav-item has_dropdown'>
                        <Link to='#' className='nav-link'>
                          Home
                        </Link>
                        <span className='drp_btn'>
                          <i className='icofont-rounded-down'></i>
                        </span>
                        <div className='sub_menu'>
                          <ul>
                            <li>
                              <Link to='/'>Home Defoult</Link>
                            </li>
                            <li>
                              <Link to='/home-dark'>Home Dark Hero</Link>
                            </li>
                            <li>
                              <Link to='/home-wave'>Home Wave </Link>
                            </li>
                            <li>
                              <Link to='/home-gredient'>Home Gredient</Link>
                            </li>
                            <li>
                              <Link to='/home-video'>Home Video</Link>
                            </li>
                            <li>
                              <Link to='/home-video2'>Home Video 2</Link>
                            </li>
                          </ul>
                        </div>
                      </li> */}
                {/* <li className='nav-item'>
                  <Link smooth to='/' className='nav-link'>
                    Home
                  </Link>
                </li> */}
                {/* <li className='nav-item'>
                  <Link smooth to='#features' className='nav-link'>
                    Features
                  </Link>
                </li> */}
                {/* <li className='nav-item'>
                  <Link smooth to='#how_it_work' className='nav-link'>
                    How it works
                  </Link>
                </li> */}
                {/* <li className='nav-item has_dropdown'>
                        <Link to='#' className='nav-link'>
                          Pages
                        </Link>
                        <span className='drp_btn'>
                          <i className='icofont-rounded-down'></i>
                        </span>
                        <div className='sub_menu'>
                          <ul>
                            <li>
                              <Link to='/about'>About Us</Link>
                            </li>
                            <li>
                              <Link to='/review'>Reviews</Link>
                            </li>
                            <li>
                              <Link to='/contact'>Contact Us</Link>
                            </li>
                            <li>
                              <Link to='/faq'>Faq</Link>
                            </li>
                            <li>
                              <Link to='/sign-in'>Sign In</Link>
                            </li>
                            <li>
                              <Link to='/sign-up'>Sign Up</Link>
                            </li>
                            <li>
                              <Link to='/blog'>Blog List</Link>
                            </li>
                            <li>
                              <Link to='/blog-single'>Blog Single</Link>
                            </li>
                          </ul>
                        </div>
                      </li> */}
                {/* <li className='nav-item'>
                  <Link to='#pricing' className='nav-link'>
                    Pricing
                  </Link>
                </li> */}
                {/* <li className='nav-item has_dropdown'>
                        <Link to='#' className='nav-link'>
                          Blog
                        </Link>
                        <span className='drp_btn'>
                          <i className='icofont-rounded-down'></i>
                        </span>
                        <div className='sub_menu'>
                          <ul>
                            <li>
                              <Link to='/blog'>Blog List</Link>
                            </li>
                            <li>
                              <Link to='/blog-single'>Blog Single</Link>
                            </li>
                          </ul>
                        </div>
                      </li> */}
                {/* <li className='nav-item'>
                        <Link to='/contact' className='nav-link'>
                          Contact
                        </Link>
                      </li> */}
                {/* <li className='nav-item'>
                        <Link to='/contact' className='nav-link dark_btn'>
                          DOWNLOAD
                        </Link>
                      </li> */}
              </ul>
            </div>
            <ul className='social_media'>
              {/* <li>
                        <Link to='#'>
                          <i className='icofont-facebook'></i>
                        </Link>
                      </li> */}
              <li>
                <Link to='https://twitter.com/galacticscenter' target='_blank'>
                  <i className='icofont-twitter'></i>
                </Link>
              </li>
              <li>
                <Link
                  to='https://www.instagram.com/galactics.center'
                  target='_blank'
                >
                  <i className='icofont-instagram'></i>
                </Link>
              </li>
              <li>
                <Link
                  to='https://www.tiktok.com/@galactics.center'
                  target='_blank'
                >
                  <IconBrandTiktok strokeWidth={1} size={20} />
                  {/* <i className='icofont-pinterest'></i> */}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Main;
